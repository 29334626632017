import styled from "styled-components";
import StatList from "../components/StatList";
import StatListItem from "../components/StatListItem";
import { FlexRow, api, scheme } from "../components/base";
import Body from "../containers/Body";
import { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
const TabHeader = styled(FlexRow)`
  .supply-header {
    font-size: 1rem; /* Larger font size */
  }

  height: 64px;
`;

function RichlistPage() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0.0);

  useEffect(() => {
    (async () => {
      const res = await axios.get("https://api-ext.bgeoex.com/rank?limit=200");
      setData(res?.data || []);
      console.log(res?.data);
      const totalBal = res?.data.reduce(
        (accumulator: any, currentValue: any) => accumulator + parseFloat(currentValue.balance || "0.0"),
        0.0,
      );  
      setTotal(totalBal);
    })();
  }, []);

  return (
    <Body>
      <TabHeader ai="center" ml={"4px"}>
        <h1
          style={{ color: scheme.accent, fontSize: "1.2rem" }}
          className="supply-header"
        >
          Rich List
        </h1>
      </TabHeader>

      <div className="flex flex-col mt-[30px] pb-[100px]">
        <div className="mb-[20px]">Balanced</div>

        <div className="flex flex-col rounded-[5px] border-[1px] border-gray-500">
          <div className="text-[40px] font-[700] p-[20px] bg-[#1e2423] rounded-t-[5px]">
            Top 100 - Current Balance
          </div>
          <table>
            <tr className="border-t-[1px] border-gray-500 p-[10px] text-left">
              <th className="p-[10px] border-r-[1px] border-gray-500 text-center">
                #
              </th>
              <th className="p-[10px] border-r-[1px] border-gray-500">
                Address
              </th>
              <th className="p-[10px] border-r-[1px] border-gray-500">
                Balance (BGEO)
              </th>
              <th className="p-[10px] border-r-[1px] border-gray-500 text-center">
                %
              </th>
            </tr>
            {data.slice(0, 100).map((e: any, i) => (
              <tr
                key={i}
                className="border-t-[1px] border-gray-500 p-[10px] text-left"
              >
                <td className="p-[10px] border-r-[1px] border-gray-500 text-center">
                  {i + 1}
                </td>
                <td className="p-[10px] border-r-[1px] border-gray-500">
                  {e?.address}
                </td>
                <td className="p-[10px] border-r-[1px] border-gray-500">
                  {e?.balance}
                </td>
                <td className="p-[10px] border-r-[1px] border-gray-500">
                  {(parseFloat(e?.balance) / total * 100).toFixed(2)}
                </td>
              </tr>
            ))}
          </table>
          <div></div>
        </div>
      </div>
    </Body>
  );
}

export default RichlistPage;
