import bgeologo from "../assets/bgeologo.png";
import { FlexRow } from "./base";
import { dirname } from "path";

function Logo() {
  return (
    <FlexRow mr={'16px'}>
      <img src={bgeologo} style={{ width: '48px', height: '48px' }}/>
    </FlexRow>
  );
}

export default Logo;
