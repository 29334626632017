import styled from 'styled-components';
import {FlexRow, scheme} from './base';

const TxDetailKey= styled(FlexRow)`
  min-width: 128px;
  margin-left: 8px;
  font-weight: 600;
  color: ${scheme.gray};
`

const TxDetailValue = styled(FlexRow)`
  margin-left: 16px;
  font-size: 1rem;
`

export { TxDetailKey, TxDetailValue };
