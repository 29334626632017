import { BeatLoader } from 'react-spinners';
import Card from '../containers/Card';
import { FlexRow, scheme } from './base';

type Props = {
  label: string;
  value: string | number;
  total: string | number;
  isFetching?: boolean;
}

function Stat({label, value, total, isFetching}: Props) {
  return (
    <Card>
      <p style={{ color: scheme.gray }} className={''}>{label}</p>
      <div className={`flex text-[${scheme.accent}] text-2xl items-end`}>
      {
        isFetching? 
        <FlexRow mt={'8px'} pd={'8px'} wd={'45%'} hg={'32px'} ai={"center"}>
          <BeatLoader color={scheme.accent} size={12}/>
        </FlexRow>:
        <FlexRow jc={'center'}>
          <p className={`flex text-[${scheme.accent}] text-2xl`}>{value}</p>
          <p>&nbsp;/&nbsp;</p>
          <p className={'text-base'}>{total}</p>
        </FlexRow>
      }
      </div>
    </Card>
  );
}

export default Stat;
