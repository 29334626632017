import {ReactNode} from 'react';
import { FlexColumn, getvw } from '../components/base';

type Props = {
  children?: ReactNode;
};

function Body({children}: Props) {
  return (
    <FlexColumn wd={getvw(6)} ml={getvw(1, 0)} mr={getvw(1, 0)} >
      {children}
    </FlexColumn>
  );
}

export default Body;