import React, {FC, ReactNode} from 'react';
import {FlexColumn, FlexRow, getvw, scheme} from './base';
import {BeatLoader} from 'react-spinners';
import styled from 'styled-components';

const DashMonitorCardWrapper = styled(FlexColumn)`
  padding: 16px;
  margin: 8px 8px;
  width: ${getvw(1)};
  min-width: 128px;
  margin-left: 0px;
  margin-right: 16px;
  height: calc(100% - 16px);
  background-color: ${scheme.primary};
  border-radius: 8px;
`;

interface IDashMonitorCardRenderProps {
  title: string;
  value: any;
  isFetching?: boolean;
  icon?: ReactNode;
};

const DashMonitorCard: FC<IDashMonitorCardRenderProps> = ({title, value, isFetching, icon}) => {
  return (
    <DashMonitorCardWrapper>
      <FlexRow ai={"center"}>
        {icon}
        <p style={{ color: scheme.gray, fontWeight: 600, marginLeft: icon? '0.4rem': '0px'}}>{title}</p>
      </FlexRow>
      {
        isFetching?
          <FlexRow wd={'100%'} hg={'100%'} jc={"center"} ai={"center"}>
            <BeatLoader color={scheme.accent} size={12}/>
          </FlexRow>:
            <p className={'flex text-[#D4312E] text-2xl'}>{value}</p>
          
      }
    </DashMonitorCardWrapper>
  )
};

export default DashMonitorCard;
