import React, {FC, useEffect, useState} from 'react';
import {api, FlexColumn, FlexRow, formatNumber, scheme} from './base';
import Card from '../containers/Card';
import {IoCloseSharp} from 'react-icons/io5';
import {TxDetailKey, TxDetailValue} from './TxDetail';
import {ITx} from '../types';


interface ITxCardRender {
  tx: ITx;
  onClose: (e: any) => void;
};

const TxCard: FC<ITxCardRender> = ({ tx, onClose }) => {
  console.log(tx);
  const [data, setData] = useState<any>(null);

  const txhash = (tx: any) => tx[2];
  const address = (tx: any) => tx[1];

  useEffect(() => {
    const txh = txhash(tx);
    txh && api.get(`/ext/gettx/${txh}`)
      .then((rspn: any) => {
        setData(rspn.data);
      })
  }, [tx]);

  console.log(data?.tx);

  return (
    <Card style={{ marginTop: '16px', padding: 0}}>
      <FlexRow wd={'100%'} jc={'space-between'} ai={'center'} bb={`0.09rem solid #282D2B`}>
        <p style={{ width: '100%', color: scheme.gray }} className={'p-4 text-base '}>{txhash(tx)}</p>
        <IoCloseSharp onClick={onClose} style={{ margin: '8px' }} size={'1.8rem'}/>
      </FlexRow>
      <div>
        <FlexRow pd={'16px 8px'} wd={'100%'}>
          <TxDetailKey>
            {data && 'Block Hash'}
          </TxDetailKey>
          <TxDetailValue onMouseOver={
            (e) => {
              e.currentTarget.style.cursor = 'pointer';
            }} onClick={() => {
            navigator.clipboard.writeText(data.tx?.blockhash);
            alert('Copied to clipboard')
          }}>
            {data && data.tx?.blockhash}
          </TxDetailValue>
        </FlexRow>
        <FlexRow pd={'16px 8px'} wd={'100%'}>
          <TxDetailKey>
            {data && 'Timestamp'}
          </TxDetailKey>
          <TxDetailValue>
            {data && data.tx.timestamp}
          </TxDetailValue>
        </FlexRow>
        <FlexColumn wd={'100%'} pd={'8px 0px'}>
          <FlexRow pd={'8px'}>
            <FlexRow wd={'100%'}>
              <TxDetailKey>
                {data && 'Confirmations'}
              </TxDetailKey>
              <TxDetailValue>
                {data && data.confirmations}
              </TxDetailValue>
            </FlexRow>
          </FlexRow>
          <FlexRow pd={'8px'}>
            <FlexRow wd={'100%'}>
              <TxDetailKey>
                {data && 'Block Count'}
              </TxDetailKey>
              <TxDetailValue>
                {data && formatNumber(data.blockcount)}
              </TxDetailValue>
            </FlexRow>
          </FlexRow>
          <FlexRow pd={'8px'} wd={'100%'}>
            <FlexRow wd={'100%'}>
              <TxDetailKey>
                {data && 'Vin'}
              </TxDetailKey>
              <FlexColumn wd={'100%'}>
                {
                  data?.tx.vin.map((vin: any) =>
                    <FlexRow pd={'0px 8px'} wd={'100%'} jc={'space-between'}>
                      <TxDetailKey>
                        {data && vin.addresses}
                      </TxDetailKey>
                      <TxDetailValue>
                        {formatNumber(vin.amount)}
                      </TxDetailValue>
                    </FlexRow>
                  )
                }
              </FlexColumn>
            </FlexRow>
          </FlexRow>
          <FlexRow pd={'8px'} wd={'100%'}>
            <TxDetailKey>
              {data && 'Vout'}
            </TxDetailKey>
            <FlexColumn wd={'100%'}>
              {
                data?.tx.vout.map((vin: any) =>
                  <FlexRow pd={'0px 8px'} wd={'100%'} jc={'space-between'}>
                    <TxDetailKey onMouseOver={
                      (e) => {
                        e.currentTarget.style.cursor = 'pointer';
                      }} onClick={() => {
                      navigator.clipboard.writeText(vin.addresses);
                      alert('Copied to clipboard')
                    }}>
                      {data && vin.addresses}
                    </TxDetailKey>
                    <TxDetailValue>
                      {data && formatNumber(vin.amount)}
                    </TxDetailValue>
                  </FlexRow>
                )
              }
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <FlexColumn pd={'8px 0px'}>
        </FlexColumn>
      </div>
    </Card>
  )
};

export default TxCard;
