import SupplyPage from "./SupplyPage";
import InspectorPage from "./InspectorPage";
import Home from "./home";
import RichlistPage from "./RichlistPage";

export default [
  { path: "/", element: <Home /> },
  { path: "/supply", element: <SupplyPage /> },
  { path: "/richlist", element: <RichlistPage /> },
  // { path: "/inspector", element: <InspectorPage /> }
];
