import Card from '../containers/Card';
import {CSSProperties, ReactNode} from 'react';
import { scheme } from './base';

type Props = {
  style?: CSSProperties;
  header: string;
  children?: ReactNode;
}
function StatList({style, header, children}: Props) {

  return (
    // 1.5rem padding is same as tailwind p-6
    <Card style={{...style, padding: 0}}>
      <p style={{ color: scheme.gray }} className={'p-4 text-base border-b-[0.09rem] border-b-[#282D2B]'}>{header}</p>
      <div>
        {children}
      </div>
    </Card>
  );
}

export default StatList;
