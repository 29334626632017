import {CSSProperties, ReactNode} from 'react';
import { FlexColumn, scheme } from '../components/base';

type Props = {
  children?: ReactNode;
  style?: CSSProperties;
}

function Card({children, style}: Props) {
  return (
    <div style={style} className={'flex p-6 pl-4 flex-col w-full bg-[#1E2423] rounded-md border-[#1c1f24] border-[0.07rem]'}>
      {children}
    </div>
  );
}

export default Card;
