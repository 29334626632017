import {ReactNode} from 'react';
import { FlexRow, getvw, scheme } from './base';
import { BeatLoader } from 'react-spinners';

type Props = {
  children?: ReactNode;
  label: string;
  link?: string;
  isFetching?: boolean;
}

function StatListItem({children, label, link, isFetching}: Props) {
  return (
    <div className={'flex justify-between p-4 border-t-[0.09rem] border-t-[#282D2B]'}>
      <p className={'text-sm'} style={{ color: scheme.gray }}>{label}</p>
      <p style={{ color: scheme.gray }} className={'text-[0.8125rem]'}>
        {
          isFetching? <BeatLoader color={scheme.accent} size={6}/>: (link ? <a href={ link }>{children}</a> : children) 
        }
      </p>
        
    </div>
  );
}

type TxProps = {
  children?: ReactNode;
  address: string;
  block: string;
  isFetching?: boolean;
  onClick: (e: any) => void;
}

export const TxListItem = ({children, block, address, isFetching, onClick }: TxProps) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }} className={'flex p-4 border-t-[0.09rem] border-t-[#282D2B]'}>
      <FlexRow wd={getvw(1)}>
        <p className={'text-sm'} style={{ color: scheme.gray }}>{block}</p>
      </FlexRow>
      <FlexRow jc={'space-between'} wd={'100%'}>
        <p className={'text-sm'} style={{ color: scheme.gray }}>{address}</p>

        <p style={{ color: scheme.gray }} className={'text-[0.8125rem]'}>
          {children}
        </p>
      </FlexRow>
        
    </div>
  );
}

export default StatListItem;
