import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { scheme } from "./base";
import styled from "styled-components";
import Card from "../containers/Card";
import axios from "axios";

// const data = [
//   { ts: "12:00", TPS: 3100 },
//   { ts: "12:10", TPS: 3214 },
//   { ts: "12:20", TPS: 2600 },
//   { ts: "12:30", TPS: 2900 },
//   { ts: "12:40", TPS: 3000 },
//   { ts: "12:50", TPS: 3100 },
//   { ts: "13:00", TPS: 3300 },
//   { ts: "13:10", TPS: 2800 },
//   { ts: "13:20", TPS: 2950 },
//   { ts: "13:30", TPS: 2750 },
//   { ts: "13:40", TPS: 2900 },
//   { ts: "13:50", TPS: 3100 },
//   { ts: "14:00", TPS: 3200 },
//   { ts: "14:10", TPS: 3000 },
//   { ts: "14:20", TPS: 3100 },
//   { ts: "14:30", TPS: 3200 },
//   { ts: "14:40", TPS: 3300 },
//   { ts: "14:50", TPS: 3400 },
//   { ts: "15:00", TPS: 3500 },
//   { ts: "15:10", TPS: 3600 },
//   { ts: "15:20", TPS: 3700 },
//   { ts: "15:30", TPS: 3800 },
//   { ts: "15:40", TPS: 3900 },
//   { ts: "15:50", TPS: 4000 },
//   { ts: "16:00", TPS: 4100 },
//   { ts: "16:10", TPS: 4200 },
//   { ts: "16:20", TPS: 4300 },
//   { ts: "16:30", TPS: 4400 },
//   { ts: "16:40", TPS: 4500 },
// ];

const StyledBarChart = styled(BarChart)`
  .recharts-bar-rectangle:hover {
    stroke: none !important;
  }
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-sm bg-[#88888888] rounded-2xl p-3">
        <p className="">{`Date: ${label}`}</p>
        <p className="">{`TPS: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const LiveTransactionChart: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await axios.get(
        "https://api-ext.bgeoex.com/tps?interval=20&limit=100"
      );
      setData(res?.data.reverse() || []);
    })();
  }, []);



  return (
    <Card>
      <p style={{ color: scheme.gray, fontWeight: 600, marginBottom: "1rem" }}>
        Live Transaction Stats
      </p>
      <ResponsiveContainer width="100%" height={400}>
        <StyledBarChart data={data}>
          <XAxis dataKey="ts" />
          <YAxis
            tickLine={false}
            tick={{ fill: scheme.gray, fontSize: "0.7rem" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="TPS" fill={scheme.accent} />
        </StyledBarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default LiveTransactionChart;
