import styled, { keyframes, css, } from "styled-components";
import axios from 'axios';

export function formatNumber(num: number) {
  return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const api = axios.create({
  // baseURL: 'http://bgeoex.com:3000', // 기본 URL 설정
  timeout: 10000, // 요청 타임아웃 설정 (밀리초)
  headers: {
    'Content-Type': 'application/json', // 기본 헤더 설정
    'Authorization': 'Bearer YOUR_TOKEN_HERE' // 인증 토큰 설정
  }
});

export const getvw = (mul: number, inset: number = 1, borderOffset: number = 0) => (`calc(${mul*2}px + ${mul * 12.5}vw - ${inset* 16}px - ${borderOffset}px)`);

// export const getvw = (mul: number) => `${mul * 12.5}vw`;
export const getvh = (mul: number) => `${mul * 12.5}vh`;

export const scheme = {
  primary: '#1E2423',
  secondary: '',
  gray: '#BCBCBC',
  accent: "#D4312E",
  outline: '#0d1117' ,
  white: '#F7F7F7',
  error: '#FF6F61',
};


const commonStyles = css<{
  bc?: string, ml?: string, mr?: string, mt?: string, mb?: string, jc?: string, ai?: string, wd?: string, hg?: string, bb?: string, bd?: string, br?: string, 
  pd?: string, pt?: string, pb?: string, pr?: string, pl?: string,
}>`

  ${(props) => props.bc? `background-color: ${props.bc}`: ``};
  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};

  ${(props) => props.jc? `justify-content: ${props.jc}`: ``};
  ${(props) => props.ai? `align-items: ${props.ai}`: ``};

  ${(props) => props.wd? `width: ${props.wd}`: ``};
  ${(props) => props.hg? `height: ${props.hg}`: ``};

  ${(props) => props.bb? `border-bottom: ${props.bb}`: ``};
  ${(props) => props.bd? `border: ${props.bd};`: ``}
  ${(props) => props.br? `border-radius: ${props.br};`: ``}

  ${(props) => props.pb? `padding-bottom: ${props.pb};`: ``}
  ${(props) => props.pt? `padding-top: ${props.pt};`: ``}
  ${(props) => props.pr? `padding-right: ${props.pr};`: ``}
  ${(props) => props.pl? `padding-left: ${props.pl};`: ``}

  ${(props) => props.pd? `padding: ${props.pd};`: `4px`}
`;

type CommonProps = {
  bc?: string,
  ml?: string,
  mr?: string,
  mt?: string,
  mb?: string,
  jc?: string,
  ai?: string,
  wd?: string,
  hg?: string,
  bb?: string,
  bd?: string,
  br?: string,
  pd?: string,
  pt?: string,
  pl?: string,
  pr?: string,
  pb?: string,
};

export const FlexColumn = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  ${commonStyles}
`;

export const FlexRow = styled.div
  <{ 
    c?: string, bc?: string, ml?: string, mr?: string, mt?: string, mb?: string, jc?: string, ai?: string, wd?: string, hg?: string, bb?: string, bd?: string, br?: string,
    pd?: string, pt?: string, pb?: string,
  }>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  ${(props) => props.c? `color: ${props.c};`: ``}
  ${(props) => props.bc? `background-color: ${props.bc};`: ``}
  ${(props) => props.mr? `margin-right: ${props.mr};`: ``}
  ${(props) => props.ml? `margin-left: ${props.ml};`: ``}
  ${(props) => props.mb? `margin-bottom: ${props.mb};`: ``}
  ${(props) => props.mt? `margin-top: ${props.mt};`: ``}

  ${(props) => props.jc? `justify-content: ${props.jc};`: ``}
  ${(props) => props.ai? `align-items: ${props.ai};`: ``}

  ${(props) => props.wd? `width: ${props.wd};`: ``}
  ${(props) => props.hg? `height: ${props.hg};`: ``}

  ${(props) => props.bb? `border-bottom: ${props.bb};`: ``}
  ${(props) => props.bd? `border: ${props.bd};`: ``}
  ${(props) => props.br? `border-radius: ${props.br};`: ``}

  ${(props) => props.pb? `padding-bottom: ${props.pb};`: ``}
  ${(props) => props.pt? `padding-top: ${props.pt};`: ``}

  ${(props) => props.pd? `padding: ${props.pd};`: ``}

`;

export const Paragraph = styled.p<{fw?: number, fs?: number, c?: string, ml?: string, mr?: string, mt?: string, mb?: string}>`
  margin: 0px;
  padding: 0px;

  ${(props) => props.fw? `font-weight: ${props.fw};` : ``};
  ${(props) => props.fs? `font-size: ${props.fs}px;` : ``};
  ${(props) => props.c? `color: ${props.c};` : ``};

  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};

`;

export const Paragraph12 = styled(Paragraph)`
  font-size: 12px;
`;

export const Paragraph14 = styled(Paragraph)`
  font-size: 14px;
`;

export const Paragraph16 = styled(Paragraph)`
  font-size: 16px;
`;

export const Paragraph18 = styled(Paragraph)`
  font-size: 18px;
`;

export const Paragraph24 = styled(Paragraph)`
  font-size: 24px;
`;

export const TagSpan = styled(FlexRow)<{ ml?: string, mr?: string, mt?: string, mb?: string, bc?: string, c?: string,}>`
  align-items: center;
  justify-content: center;

  height: 24px;
  min-width: 58px;
  border-radius: 4px;
  backgroundColor: #FF6B6B;

  padding-left: 10px;
  padding-right: 10px;

  color: ${(props) => props.c || scheme.white};
  background-color: ${(props) => props.bc? props.bc: scheme.accent};
  font-weight: 600;
  font-size: 14px;

  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// FadeOut 애니메이션 키프레임 정의
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FadeInFadeOutWrapper = styled.div<{ visible: boolean }>`
  ${(props) => !props.visible ? 'display: none;': ''};
  animation: ${({visible}) => (visible? fadeIn : fadeOut)} 1.2s linear;
`;

export const AppContainer = styled(FlexColumn)`
  margin-left: ${getvw(1, 0)};
  width: ${getvw(7, 0)};
  height: 100vh;
  color: ${scheme.white};
  background-color: ${scheme.secondary};

  margin: 0px;
  padding: 0px;

  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;
`;

export const GridItem = styled(FlexRow)<{ gc?: string, gr?: string }>`
  // background-color: #f0f0f0; // 배경 색상
  padding: 0px; // 패딩


  ${(props) => props.gc ? `grid-column: ${props.gc}`: ``};
  ${(props) => props.gr ? `grid-row: ${props.gr}`: ``};
`;


export const ZButton = styled(FlexRow)<{ bc?: string, wd?:string, hg?: string; mt?: string, mb?: string, ml?: string, mr?: string }>`
  background-color: ${scheme.accent};
  width: 64px;
  height: 32px;

  border-radius: 8px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};

  ${(props) => props.bc? `background-color: ${props.bc}`: ``};

  ${(props) => props.wd? `width: ${props.wd};`: ``}
  ${(props) => props.hg? `height: ${props.hg};`: ``}
  
`;

export const ZCheckbox = styled.input.attrs({ type: 'checkbox' })<{
    c?: string, bc?: string, wd?: string, hg?: string, mt?: string, mb?: string, ml?: string, mr?: string, bd?: string }>`
  border: 1px solid ${scheme.accent};
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

  background: transparent;
  color: ${scheme.white};

  ${(props) => props.c ? `color: ${props.c};` : ``}
  ${(props) => props.mr ? `margin-right: ${props.mr}` : ``};
  ${(props) => props.ml ? `margin-left: ${props.ml}` : ``};
  ${(props) => props.mb ? `margin-bottom: ${props.mb}` : ``};
  ${(props) => props.mt ? `margin-top: ${props.mt}` : ``};

  ${(props) => props.bc ? `background-color: ${props.bc}` : ``};

  ${(props) => props.wd ? `width: ${props.wd};` : ``}
  ${(props) => props.hg ? `height: ${props.hg};` : ``}

  ${(props) => props.bd ? `border: ${props.bd};` : ``}

`;

export const ZInput = styled.input<
  { c?: string, bc?: string, wd?:string, hg?: string; mt?: string, mb?: string, ml?: string, mr?: string, bd?: string, fw?: string| number }
>`
  border: none;
  outline: none;

  border-radius: 8px;
  padding-left: 8px;

  // background: transparent;
  background: ${scheme.secondary};
  color: ${scheme.white};
  box-sizing: border-box;

  ${(props) => props.c? `color: ${props.c};`: ``}
  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};

  ${(props) => props.bc? `background-color: ${props.bc}`: ``};

  ${(props) => props.wd? `width: ${props.wd};`: ``}
  ${(props) => props.hg? `height: ${props.hg};`: `height: 36px;`}

  ${(props) => props.bd? `border: ${props.bd};`: ``}
  ${(props) => props.fw? `font-weight: ${props.fw};`: ``}
`;

export const ZTextarea= styled.textarea<{ bc?: string, wd?:string, hg?: string; mt?: string, mb?: string, ml?: string, mr?: string, bd?: string }>`
  border: ${(props) => props.bd || `1px solid ${scheme.accent}`};
  border-radius: 8px;
  padding: 8px;

  background: ${scheme.secondary};
  color: ${scheme.white};

  ${(props) => props.mr? `margin-right: ${props.mr}`: ``};
  ${(props) => props.ml? `margin-left: ${props.ml}`: ``};
  ${(props) => props.mb? `margin-bottom: ${props.mb}`: ``};
  ${(props) => props.mt? `margin-top: ${props.mt}`: ``};

  ${(props) => props.bc? `background-color: ${props.bc}`: ``};

  ${(props) => props.wd? `width: ${props.wd}`: ``};
  ${(props) => props.hg? `height: ${props.hg}`: ``};
`;

export const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const PageContainer = styled(FlexColumn)`
  padding: 0px 32px;
  width: 100%;
  height: 100%;
`;
