import {Link, useLocation} from 'react-router-dom';
import { scheme } from './base';

type Props = {
  text: string;
  link: string;
}

function NavigateButton({ text, link }: Props) {
  const location = useLocation();
  const isCurrentPage = location.pathname === '/' + link;

  return (
      <Link className={'flex flex-col py px-3'} to={'/' + link}>
        <div className={'mb-auto h-[0.1rem]'} />
        <p style={{color: isCurrentPage ? scheme.accent: '#698582'}}>{text}</p>
        <div style={{ backgroundColor: isCurrentPage ? scheme.accent : 'transparent'}} className={'flex h-[0.1rem] w-full mt-auto'} />
      </Link>

  );
}

export default NavigateButton;
