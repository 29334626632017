import React, {FC} from 'react';
import Card from '../containers/Card';
import {FlexRow, scheme} from './base';
import {IoCloseSharp} from 'react-icons/io5';
import styled from 'styled-components';

interface IAddressCardRender {
  wallet: any;
  onClose: (e: any) => void;
};

const TxDetailKey= styled(FlexRow)`
  min-width: 128px;
  margin-left: 8px;
  font-weight: 600;
  color: ${scheme.gray};
`

const TxDetailValue = styled(FlexRow)`
  margin-left: 16px;
  font-size: 1rem;
`


const WalletCard: FC<IAddressCardRender> = ({ wallet, onClose }) => {
  return (
    <Card style={{ marginTop: '16px', padding: 0}}>
      <FlexRow wd={'100%'} jc={'space-between'} ai={'center'} bb={`0.09rem solid #282D2B`}>
        <p style={{ width: '100%', color: scheme.gray }} className={'p-4 text-base '}>{wallet.address}</p>
        <IoCloseSharp onClick={onClose} style={{ margin: '8px' }} size={'1.8rem'}/>
      </FlexRow>
      <FlexRow pd={'16px 8px'} wd={'100%'}>
        <TxDetailKey> balance </TxDetailKey>
        <TxDetailValue>{ wallet.balance} </TxDetailValue>
      </FlexRow>
      <FlexRow>
        <FlexRow pd={'16px 8px'} wd={'50%'}>
          <TxDetailKey> received </TxDetailKey>
          <TxDetailValue>{wallet.received} </TxDetailValue>
        </FlexRow>
        <FlexRow pd={'16px 8px'} wd={'50%'}>
          <TxDetailKey> received </TxDetailKey>
          <TxDetailValue>{wallet.received} </TxDetailValue>
        </FlexRow>
      </FlexRow>
    </Card>
  );
}

export default WalletCard;
