import Card from '../containers/Card';
import { IoSearch } from "react-icons/io5";
import { FlexRow, scheme } from './base';
import { FC } from 'react';
import { BeatLoader } from 'react-spinners';

interface ISearchBarRenderProps {
  onEnter: (e: any) => void;
  onChange: (e: any) => void;
  isSearching: boolean;
}
const SearchBar: FC<ISearchBarRenderProps> = ({ onEnter, onChange, isSearching }) => {

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onEnter(event);
    }
  };

  return (
    <Card style={{padding: 0}}>
      <FlexRow wd={'100%'} ai={'center'} style={{ position: 'relative', }}>
        <input 
          style={{ outline: 'none' }}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          className={'min-w-full bg-transparent p-3'} type="text" placeholder="Search for blocks, account, transactions, programs, and tokens" />
          {
            isSearching? 
            <BeatLoader size={6} color={scheme.accent} style={{ position: 'absolute', right: '16px', }}/> : 
            <IoSearch size={'16px'} style={{ position: 'absolute', right: '16px', }}/>
          }
      </FlexRow>
    </Card>
  );
}

export default SearchBar;
