import styled from "styled-components";
import StatList from "../components/StatList";
import StatListItem from "../components/StatListItem";
import { FlexRow, api, scheme } from "../components/base";
import Body from "../containers/Body";
import { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
const TabHeader = styled(FlexRow)`
  .supply-header {
      font-size: 1rem; /* Larger font size */
  }
  
  height: 64px;

`

function SupplyPage() {
  const [isFetching, setIsFetching] = useState(true);
  const [supply, setSupply] = useState(null);

  useEffect(() => {
    api.get('/ext/getmoneysupply')
        .then((rspn: any) => {
          console.log('d:',rspn);
          setSupply(rspn.data);
          setIsFetching(false);
        })
  }, []);

  return (
    <Body>
      <TabHeader ai="center" ml={'4px'} >
        <h1 style={{ color: scheme.accent, fontSize: '1.2rem' }} className="supply-header">Supply</h1>
      </TabHeader>
      <StatList header={'Supply Overview'}>
        <StatListItem label={'Total Supply'}>{isFetching? <BeatLoader color={scheme.accent} size={6}/> :  supply}</StatListItem>
      </StatList>
    </Body>
  );
}

export default SupplyPage;
